import axios from "axios";
import { config } from "@/common/app.config";
import TokenService from "../common/token.service";
import store from "@/store";
import router from "@/router";
const ApiService = {
  init() {
    axios.defaults.baseURL = config.url;
    axios.interceptors.response.use(
      (response) => {
        if (response.config.method == "get") {
          if (!localStorage.getItem("ui_version") || localStorage.getItem("ui_version") != response.data.ui_version) {
            localStorage.setItem("ui_version", response.data.ui_version);
            router.go();
          }
        }
        
        if (response.data.errorcode && response.data.errorcode == 503) {
          return router.replace({ name: "Maintenance" });
        }
        return response;
      },
      function(error) {
        if (error) {
          switch (error.response.status) {
            case 401: {
              store.dispatch("signOutLocal");
              return router.replace({ name: "Login", query: { redirect: router.options.history.state.back }, });
            }
            case 500: {
              store.dispatch("setError", error.response.data.error);
              break;
            }
            case 503: {
              return router.replace({ name: "Maintenance" });
            }
          }
        }
        return Promise.reject(error);
      }
    );
  },

  setHeader() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${TokenService.getToken("token")}`;
  },

  get(resource, params = {}, responseType = "") {
    return axios
      .get(`${resource}`, {
        params: params,
        responseType: responseType,
      })
      .catch((error) => {
        throw new Error(`ApiService ${error}`);
      });
  },

  post(resource, params) {
    return axios.post(`${resource}`, params);
  },
};

export default ApiService;
