export const config = {
  url: process.env.VUE_APP_API_URL,
  empUrl: process.env.VUE_APP_EMP_URL,
  hrUrl: process.env.VUE_APP_HR_URL,
  env: process.env.NODE_ENV,
};

export const apiResource = {
  auth: {
    validateEmail: "/v2/auth/validate-email",
    validateOtp: "/v2/auth/validate-otp",
    resendOtp: "/v2/auth/resend-otp",
    logout: "/v2/auth/logout",
  },
  profile: {
    getDetails: "/v2/hr/get-profile"
  },
  settings: {
    saveNotificationSettings: "/v2/hr/profile/save-notification-settings",
  },
  dashboard: {
    getDetails: "/v2/hr/get-dashboard-details",
    getTicketDetails: "/v2/hr/get-ticket-details"
  },
  tickets: {
    get: "/v2/hr/tickets/get",
    change: "/v2/hr/tickets/change-plan",
  },
  onboardingKit: {
    getAllKits: "/v2/hr/get-onboarding-kits",
    catalog: "/v2/hr/onboardingkit/catalog-experiences",
    experienceDetails: "/v2/hr/onboardingkit/experience-details",
    experienceCategories: "/v2/hr/onboardingkit/experience-categories",
    create: "/v2/hr/onboardingkit/create",
    updateName: "/v2/hr/onboardingkit/update-name",
    removeExperience: "/v2/hr/onboardingkit/experience/remove",
    removeExperienceFromCategory: "/v2/hr/onboardingkit/experience/remove-from-category",
    addedExperiences: "/v2/hr/onboardingkit/added-experiences",
    saveAddedExpsProgress: "/v2/hr/onboardingkit/save-progress",
    save: "/v2/hr/onboardingkit/save",
    experiences: {
      welcomeVideo: {
        add: "/v2/hr/onboardingkit/welcome-video/add",
        update: "/v2/hr/onboardingkit/welcome-video/update",
      },
      evp: {
        d3PreviewData: "/v2/hr/onboardingkit/evp/d3-preview-data",
        add: "/v2/hr/onboardingkit/evp/add",
        update: "/v2/hr/onboardingkit/evp/update",
      },
      inductionVideo: {
        metaData: "/v2/hr/get-meta-data",
        getDetails: "/v2/hr/onboardingkit/induction-video/get-details",
        saveDetails: "/v2/hr/onboardingkit/induction-video/save-details",
        add: "/v2/hr/onboardingkit/induction-video/add",
        update: "/v2/hr/onboardingkit/induction-video/update"
      },
      documentLibrary: {
        getDocuments: "/v2/hr/onboardingkit/document-library/get-documents",
        getDocumentDetails:
          "/v2/hr/onboardingkit/document-library/get-document-details",
        addDocument:
          "/v2/hr/onboardingkit/document-library/add-document",
        removeDocument:
          "/v2/hr/onboardingkit/document-library/remove-document",
        add:
          "/v2/hr/onboardingkit/document-library/add",
        update:
          "/v2/hr/onboardingkit/document-library/update",
      },
      nha: {
        getSampleProfile: "/v2/hr/onboardingkit/nha/sample-profile",
        add: "/v2/hr/onboardingkit/nha/add",
        update: "/v2/hr/onboardingkit/nha/update",
      },
      welcome360: {
        preview: "/v2/hr/onboardingkit/welcome-360/preview",
        add: "/v2/hr/onboardingkit/welcome-360/add",
        update: "/v2/hr/onboardingkit/welcome-360/update",
      },
      ttal: {
        add: "/v2/hr/onboardingkit/ttal/add",
        update: "/v2/hr/onboardingkit/ttal/update",
      },
      scavengerHunt: {
        add: "/v2/hr/onboardingkit/scavenger-hunt/add",
        update: "/v2/hr/onboardingkit/scavenger-hunt/update",
      },
      gtm: {
        add: "/v2/hr/onboardingkit/gtm/add",
        update: "/v2/hr/onboardingkit/gtm/update",
      },
      managerNPS: {
        add: "/v2/hr/onboardingkit/manager-nps/add",
        update: "/v2/hr/onboardingkit/manager-nps/update",
      },
      pulse1Week: {
        add: "/v2/hr/onboardingkit/pulse-1-week/add",
        update: "/v2/hr/onboardingkit/pulse-1-week/update",
      },
      candidateExpSurvey: {
        add: "/v2/hr/onboardingkit/candidate-exp-survey/add",
        update: "/v2/hr/onboardingkit/candidate-exp-survey/update",
      }
    }
  },
  users: {
    getUsers: "/v2/hr/get-users",
    getUserDetails: "/v2/hr/get-user-details",
    getCandidatesForUpdates: "/v2/hr/get-candidates-for-updates",
    removeCandidates: "/v2/hr/remove-candidates",
    convertCandidateToEmp: "/v2/hr/convert-candidate-to-emp",
    getUserProfiles: "/v2/hr/get-user-profiles",
    getUserProfileDetails: "/v2/hr/get-user-profile-details"
  },
  addUsers: {
    validateUsers: "/v2/hr/add-users/validate-users",
    getFlowSummary: "/v2/hr/add-users/get-flow-summary",
    finalizeFlow: "/v2/hr/add-users/finalize-flow",
  },
  tools: {
    catalog: "/v2/hr/tools/catalog-experiences",
    experienceDetails: "/v2/hr/tools/experience-details",
    experiences: {
      jigsaw: {
        preview: "/v2/hr/tools/jigsaw/preview",
        send: "/v2/hr/tools/jigsaw/send"
      },
      scratchCard: {
        getOptions: "/v2/hr/tools/scratch-card/get-options",
        preview: "/v2/hr/tools/scratch-card/preview",
        send: "/v2/hr/tools/scratch-card/send"
      },
      spinTheWheel: {
        send: "/v2/hr/tools/spin-the-wheel/send"
      },
      celebration: {
        preview: "/v2/hr/tools/celebrations/preview",
        send: "/v2/hr/tools/celebrations/send"
      }
    },
    getTools: "/v2/hr/get-tools",
    getToolsExps: "/v2/hr/get-tools-exps"
  },
  journeys: {
    getJourneys: "/v2/hr/get-journeys"
  },
  reports: {
    getScript: "/v2/hr/reports/script/get",
    getJourneys: "/v2/hr/reports/journeys/get"
  }
};

export const ivFields = [
  {
    name: "company_name",
  },
  {
    name: "revenue",
  },
  {
    name: "locations",
  },
  {
    name: "team",
  },
  {
    name: "video_office",
  },
  {
    name: "founded_year",
  },
  {
    name: "industry",
  },
  {
    name: "services",
  },
  {
    name: "video_ceo",
  },
  {
    name: "values",
  },
  {
    name: "video_testimonial",
  },
  {
    name: "benefits",
  },
  {
    name: "ways_of_working",
  },
  {
    name: "logo",
  },
  {
    name: "resumes",
  },
  {
    name: "video",
  },
];

export const fileSources = {
  sampleExcel: process.env.VUE_APP_SAMPLE_FILE_EMPLOYEES,
  obkSampleExcel: process.env.VUE_APP_SAMPLE_FILE_OBK_USERS,
};
