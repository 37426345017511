export default [
  {
    path: "/login",
    name: "Login",

    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/",
    name: "Dashboard",

    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/users",
    name: "Users",

    component: () =>
      import(/* webpackChunkName: "users" */ "@/views/Users/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/user-detail/:id",
    name: "UserDetail",

    component: () =>
      import(/* webpackChunkName: "userdetail" */ "@/views/Users/Detail.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/user-updates",
    name: "UserUpdates",

    component: () =>
      import(/* webpackChunkName: "userupdates" */ "@/views/Users/Updates.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/user-profiles",
    name: "UserProfiles",

    component: () =>
      import(/* webpackChunkName: "userprofiles" */ "@/views/Users/Profiles.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    name: "Profile",

    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/Profile.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",

    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/Settings.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/tickets",
    name: "Tickets",

    component: () =>
      import(/* webpackChunkName: "tickets" */ "@/views/Tickets.vue"),
    meta: { requiresAuth: true },
  },
];
