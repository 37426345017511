import { createRouter, createWebHistory } from "vue-router";
import BaseRoutes from "./Base/BaseRoutes";
import OnboardingKitRoutes from "./OnboardingKitRoutes";
import JourneyRoutes from "./Journeys";
import ToolsRoutes from "./ToolsRoutes";
import ReportsRoutes from "./ReportsRoutes";
import ExperienceElements from "./ExperienceElements";
import NotFound from "../views/NotFound.vue";
import store from "@/store";

const routes = [
  ...BaseRoutes,
  ...OnboardingKitRoutes,
  ...JourneyRoutes,
  ...ToolsRoutes,
  ...ReportsRoutes,
  ...ExperienceElements,
  {
    path: "/maintenance",
    name: "Maintenance",

    component: () =>
      import(/* webpackChunkName: "maintenance" */ "../views/Maintenance.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

    if (!store.getters.isAuthenticated) {
      next({
        name: "Login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    if (store.getters.isAuthenticated && to.name == "Login") {
      next({ name: "Dashboard" });
    } else {
      next(); // make sure to always call next()!
    }
  }
});

export default router;
