export default [
    {
      path: "/tools/catalog",
      name: "ToolsCatalog",
  
      component: () =>
        import(/* webpackChunkName: "tools" */ "@/views/Tools/Catalog.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/tools/experience/:experience_id",
      name: "ToolsExperienceForm",
  
      component: () =>
        import(
          /* webpackChunkName: "tools-exp-form" */ "@/views/Tools/ExperienceForm.vue"
        ),
      props: true,
      meta: { requiresAuth: true },
    },
    {
      path: "/tools/reports",
      name: "ToolsReports",
  
      component: () =>
        import(
          /* webpackChunkName: "toolsreports" */ "@/views/Tools/Reports.vue"
        ),
      props: true,
      meta: { requiresAuth: true },
    },
  ];
  