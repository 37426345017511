export default [
    {
        path: "/documentviewer/:id",
        name: "DocumentViewer",
    
        component: () =>
          import(
            /* webpackChunkName: "documentviewer" */ "@/views/ExperienceElements/DocumentViewer.vue"
          ),
        meta: { requiresAuth: true },
        props: true,
      },
  ];
  