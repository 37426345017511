export default [
    {
      path: "/journeys",
      name: "Journeys",
  
      component: () =>
        import(/* webpackChunkName: "journeys" */ "@/views/Journeys.vue"),
      meta: { requiresAuth: true },
    },
  ];
  