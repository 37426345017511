export default [
    {
      path: "/create-journey/catalog/:tag/:onboarding_kit_id?",
      name: "CreateOnboardingKitCatalog",
  
      component: () =>
        import(/* webpackChunkName: "createonboardingkitcatalog" */ "@/views/CreateOnboardingKit/Catalog.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/add-users",
      name: "AddUsers",
  
      component: () =>
        import(/* webpackChunkName: "addusers" */ "@/views/AddUsersToOnboardingKit/Form.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/add-users/summary/:id",
      name: "AddUsersSummary",
  
      component: () =>
        import(
          /* webpackChunkName: "add-users-summary" */ "@/views/AddUsersToOnboardingKit/Summary.vue"
        ),
      props: true,
      meta: { requiresAuth: true },
    },
    {
      path: "/add-users/success",
      name: "AddUsersSuccess",
  
      component: () =>
        import(
          /* webpackChunkName: "add-users-success" */ "@/views/AddUsersToOnboardingKit/Success.vue"
        ),
      props: true,
      meta: { requiresAuth: true },
    },
    {
      path: "/obk/experience/:onboarding_kit_id/:experience_id",
      name: "OBKitExperienceForm",
  
      component: () =>
        import(
          /* webpackChunkName: "obk-exp-form" */ "@/views/CreateOnboardingKit/ExperienceForm.vue"
        ),
      props: true,
      meta: { requiresAuth: true },
    },
    {
      path: "/obk/added-exps/:onboarding_kit_id",
      name: "OBKitAddedExps",
  
      component: () =>
        import(
          /* webpackChunkName: "obk-added-exps" */ "@/views/CreateOnboardingKit/AddedExperiences.vue"
        ),
      props: true,
      meta: { requiresAuth: true },
    },
    {
      path: "/obk/success",
      name: "CreateOnboardingKitSuccess",
  
      component: () =>
        import(
          /* webpackChunkName: "obk-success" */ "@/views/CreateOnboardingKit/Success.vue"
        ),
      props: true,
      meta: { requiresAuth: true },
    },
  ];
  