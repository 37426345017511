<template>
  <div class="font-Rajdhani flex min-h-screen">
    <div
      :class="{
        'left-gradient': !noGradient.includes($route.name),
      }"
      class="h-screen fixed"
    >
      &nbsp;&nbsp;
    </div>
    <div class="text-white flex-grow">
      <Loader v-if="isPageLoading" />
      <router-view v-else :key="$route.fullPath" v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Elements/Loader.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      noGradient: ["Login"],
    };
  },
  components: {
    Loader,
  },
  mounted() {},
  computed: {
    ...mapGetters(["isPageLoading"]),
  },
};
</script>
<style scoped>
body {
  background-color: #07003b;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.max-w-8xl {
  max-width: 96rem;
}

.route-enter-from {
  opacity: 0;
}

.route-enter-active {
  transition: all 0.5s ease-out;
}

.route-leave-to {
  opacity: 0;
}

.route-leave-active {
  transition: all 0.5s ease-in;
}
.left-gradient {
  background-image: url("~@/assets/images/left-gradient.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}
</style>
