<template>
  <div
    class="loader w-screen h-screen fixed top-0 left-0 bg-black opacity-75 flex justify-center items-center z-50"
  >
    <img :src="require('@/assets/images/loader.svg')" class="w-48 h-48" />
  </div>
</template>

<script>
export default {
  name: "Page Loader",
};
</script>

<style scoped></style>
