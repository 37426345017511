export default [
    {
        path: "/reports",
        name: "Reports",

        component: () =>
            import(/* webpackChunkName: "reports" */ "@/views/Reports/Index.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/reports/journeys",
        name: "ReportsJourneys",

        component: () =>
            import(/* webpackChunkName: "reports" */ "@/views/Reports/Journeys.vue"),
        meta: { requiresAuth: true },
    },
];
  