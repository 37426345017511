import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  state: {
    profile: null,
  },
  getters: {
    profile(state) {
      return state.profile;
    },
  },
  mutations: {
    setProfile(state, payload) {
      state.profile = payload;
    },
  },
  actions: {
    getProfile({ commit }) {
      return new Promise((resolve, reject) => {
        ApiService.get(apiResource.profile.getDetails)
          .then((response) => {
            if (response.data) {
              resolve(response.data.data);
              commit("setProfile", response.data.data);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
